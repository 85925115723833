<template>
<!--  <b-card-->
<!--    v-if="data"-->
<!--    body-class="pb-50"-->
<!--  >-->
<!--    <h6 class="text-uppercase">Clients</h6>-->
<!--    <h2 class="font-weight-bolder mb-1">-->
<!--          {{statistiqueProspect.clients ? statistiqueProspect.clients : 0 }}-->
<!--    </h2>-->
<!--    <vue-apex-charts-->
<!--      height="70"-->
<!--      :options="statisticsOrder.chartOptions"-->
<!--      :series="data.series"-->
<!--    />-->
<!--  </b-card>-->

  <b-card
      v-if="data"
      body-class="pb-50"
      class="card-tiny-line-stats text-center"
  >
    <b-avatar
        class="mb-1"
        size="45"
        variant="light-warning"
    >
      <feather-icon
          icon="UsersIcon"
          size="21"
      />
    </b-avatar>
    <div class="truncate">
      <h2 class="mb-25 font-weight-bolder">
        {{ (statistiqueProspect && statistiqueProspect.clients) ? statistiqueProspect.clients : 0 }}
      </h2>
      <span>CLIENTS</span>
    </div>

  </b-card>
</template>

<script>
import { BCard, BAvatar } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $barColor = '#f3f3f3'

export default {
  components: {
    BCard,
    BAvatar,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    statistiqueProspect: {
      type: [Array, Object],
      default: () => {},
    },
  },
  data() {
    return {
      statisticsOrder: {
        chartOptions: {
          chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
              top: -15,
              bottom: -15,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '20%',
              startingShape: 'rounded',
              colors: {
                backgroundBarColors: [$barColor, $barColor, $barColor, $barColor, $barColor],
                backgroundBarRadius: 5,
              },
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: ['#f39c12'],

          xaxis: {
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
    }
  },
}
</script>
