<template>
    <div>
        <b-overlay :show="showLoading" no-wrap />
            <b-row class="match-height">
              <b-col
                      lg="4"
                      md="4"
              >

              <StatistiqueGeneral :tarificationRapideParRisque="tarificationRapideParRisque" :nombreConnexion="nombreConnexion" :nombreUtilisateur="nombreUtilisateur" :devisParProduitStatistique="devisParProduitStatistique" :statistiqueContrats="statistiqueContrats" :statistiqueDevis="statistiqueDevis"/>
              </b-col>
              <b-col
                lg="4"
                md="4"
              >
                <MesStatistique :nombreCodeParAssureur="nombreCodeParAssureur" :contratParProduitStatistiqueByAssureur="contratParProduitStatistiqueByAssureur" :devisParProduitStatistiqueByAssureur="devisParProduitStatistiqueByAssureur" :statistiqueDevisByAssureur="statistiqueDevisByAssureur" :statistiqueContratsByAssureur="statistiqueContratsByAssureur"/>
              </b-col>
              <b-col md="4" lg="4">
                <CarrouselAssureurs :assureur_id="assureur_id" />
              </b-col>
      
            </b-row>
             <b-row class="match-height ">
      
              <b-col md="8" lg="8" style="min-height: 150px; height: 100%;">
                <b-card class="client_stats">
                    <div>
                      <b-card-title class="text-primary">Mes statistiques du mois ({{ moisAnneeFormat }})</b-card-title>
                    </div>
                  <b-card-body class="p-0">

                    <b-row>
                      <b-col
                      lg="3"
                      sm="6"
                    >
                    
                    <Code :nombreCodeParAssureurMois="nombreCodeParAssureurMois" />
                    </b-col>
                    <b-col
                      lg="3"
                      sm="6"
                    >
                      <Devis :devisStatistiqueByAssureurMois="devisStatistiqueByAssureurMois" />
                    </b-col> 
                    <b-col
                      lg="3"
                      sm="6"
                    >
                      <Contrat :contratStatistiqueByAssureurMois="contratStatistiqueByAssureurMois" />
                    </b-col>
               
                    </b-row>
                  </b-card-body>
                </b-card>
           
              </b-col>
            </b-row>
    </div>
</template>

<script>
import { BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BOverlay } from 'bootstrap-vue'
import moment from 'moment';
import 'moment/locale/fr';

import CarrouselAssureurs from '../ecommerce/assureurs/CarrouselAssureurs.vue'
import MesStatistique from '../ecommerce/assureurs/MesStatistique.vue'
import StatistiqueGeneral from '../ecommerce/assureurs/StatistiqueGeneral.vue'
import Code from '../ecommerce/assureurs/CodeActifs.vue'
import Contrat from '../ecommerce/assureurs/ContratMois.vue'    
import Devis from '../ecommerce/assureurs/DevisMois.vue'




export default {
  components: {
    BCardHeader,
    moment,
    Code,
    Contrat,
    Devis,
    BCardTitle,
    BCardText,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardBody,
    CarrouselAssureurs,
    MesStatistique,
    StatistiqueGeneral,
    },
  data() {
    return {
      showLoading: false,
      statistiqueDevis: 0,
      statistiqueContrats: 0,
      statistiqueDevisByAssureur: 0,
      statistiqueContratsByAssureur: 0,
      devisParProduitStatistique: [],
      devisParProduitStatistiqueByAssureur: [],
      contratParProduitStatistiqueByAssureur: [],
      nombreCodeParAssureur: 0,
      nombreConnexion: 0,
      nombreUtilisateur: 0,
      moisAnneeFormat: null,
      nombreCodeParAssureurMois:0,
      devisStatistiqueByAssureurMois:0,
      contratStatistiqueByAssureurMois: 0,
      tarificationRapideParRisque: [],

      

    }
  },
  props: {
    assureur_id: Number,
  },
  created() {
    this.showLoading = true

    const requests = [];
    requests.push(this.$http.post('/statistiques/getStatistiqueInterfaceAssureur',{ assureur_id : this.assureur_id })
      .then(response => {
        this.statistiqueDevis = response.data.devisStatistique
        this.statistiqueContrats = response.data.contratStatistique
        this.statistiqueDevisByAssureur = response.data.devisStatistiqueByAssureur
        this.statistiqueContratsByAssureur = response.data.contratStatistiqueByAssureur
        this.devisParProduitStatistique = response.data.devisParProduitStatistique
        this.devisParProduitStatistiqueByAssureur = response.data.devisParProduitStatistiqueByAssureur
        this.nombreUtilisateur = response.data.nombreUtilisateur
        this.nombreConnexion = response.data.nombreConnexion
        this.nombreCodeParAssureur = response.data.nombreCodeParAssureur
        this.contratParProduitStatistiqueByAssureur = response.data.contratParProduitStatistiqueByAssureur
        this.nombreCodeParAssureurMois = response.data.nombreCodeParAssureurMois
        this.devisStatistiqueByAssureurMois = response.data.devisStatistiqueByAssureurMois
        this.contratStatistiqueByAssureurMois = response.data.contratStatistiqueByAssureurMois
        this.tarificationRapideParRisque = response.data.tarificationRapideParRisque



      })
      .catch(err => {
      }))
    return Promise.all(requests).then(res => this.showLoading = false)
  },
  mounted() {
    moment.locale('fr');
    // Obtenir le mois et l'année actuels
    this.moisAnneeFormat = moment().format('MMMM YYYY');

  }
    
}
</script>


