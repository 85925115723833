<template>
        <div>
            <b-overlay :show="showLoading" no-wrap />
            <b-row class="match-height">
                <b-col lg="4" md="4">
                    <ecommerce-browser-states :statistiqueContratsAssRis="statistiqueContratsAssRis" />
                </b-col>
                <b-col lg="4" md="4">
                    <ecommerce-commissions :statistiqueCommissions="statistiqueCommissions" />
                </b-col>
                <b-col md="4" lg="4">
                    <ecommerce-meetup :data="data.meetup" />
                </b-col>

            </b-row>
            <b-row class="match-height ">

                <b-col md="8" lg="8" style="min-height: 150px; height: 100%;">
                    <b-card class="client_stats">
                        <div>
                            <b-card-title>Statistiques clients</b-card-title>
                        </div>
                        <b-card-body class="p-0">

                            <b-row>
                                <b-col lg="3" sm="6">
                                    <ecommerce-particulier :statistiqueProspect="statistiqueProspect" />
                                </b-col>
                                <b-col lg="3" sm="6">

                                    <ecommerce-professionnels :statistiqueProspect="statistiqueProspect" />
                                </b-col>
                                <b-col lg="3" sm="6">

                                    <ecommerce-entreprise :statistiqueProspect="statistiqueProspect" />
                                </b-col>
                                <b-col lg="3" sm="6">

                                    <ecommerce-total :statistiqueProspect="statistiqueProspect" />
                                </b-col>

                            </b-row>
                        </b-card-body>
                    </b-card>
                    <b-row class="match-height">
                        <b-col lg="6">
                            <analytics-timeline :data="data_analytics.timeline"
                                :statistiqueEvenements="statistiqueEvenementsMsg" />
                        </b-col>
                        <b-col lg="6">
                            <analytics-app-design :statistiqueEvenements="statistiqueEvenementsTache" />
                        </b-col>
                    </b-row>
                </b-col>
                <b-col lg="4" md="4">
                    <ecommerce-goal-overview :data="data.goalOverview" :statistiqueProjets="statistiqueProjets" />
                </b-col>
            </b-row>

        </div>
</template>

<script>
import { BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BOverlay } from 'bootstrap-vue'
import EcommerceBrowserStates from '../ecommerce/EcommerceBrowserStates.vue'
import EcommerceGoalOverview from '../ecommerce/EcommerceGoalOverview.vue'
import EcommerceOrderChart from '../ecommerce/EcommerceOrderChart.vue'
import EcommerceOrderClient from '../ecommerce/EcommerceOrderClient.vue'
import EcommerceProfitChart from '../ecommerce/EcommerceProfitChart.vue'
import EcommerceProfitContrat from '../ecommerce/EcommerceProfitContrat.vue'
import AnalyticsTimeline from '../analytics/AnalyticsTimeline.vue'
import AnalyticsSalesRadarChart from '../analytics/AnalyticsSalesRadarChart.vue'
import AnalyticsAppDesign from '../analytics/AnalyticsAppDesign.vue'
import EcommerceMeetup from '../ecommerce/EcommerceMeetup.vue'
import EcommerceParticulier from '../ecommerce/EcommerceParticulier.vue'
import EcommerceProfessionnels from '../ecommerce/EcommerceProfessionnels.vue'
import EcommerceEntreprise from '../ecommerce/EcommerceEntreprise.vue'
import EcommerceTotal from '../ecommerce/EcommerceTotal.vue'
import EcommerceCommissions from '../ecommerce/EcommerceCommissions.vue'

export default {
    components: {
        BCardHeader,
        BCardTitle,
        BCardText,
        BOverlay,
        BRow,
        BCol,
        BCard,
        BCardBody,
        EcommerceBrowserStates,
        EcommerceGoalOverview,
        EcommerceProfitChart,
        EcommerceOrderChart,
        EcommerceOrderClient,
        EcommerceProfitContrat,
        AnalyticsTimeline,
        AnalyticsSalesRadarChart,
        AnalyticsAppDesign,
        EcommerceMeetup,
        EcommerceParticulier,
        EcommerceProfessionnels,
        EcommerceEntreprise,
        EcommerceTotal,
        EcommerceCommissions
    },
    data() {
        return {
            showLoading: false,
            currentUser: JSON.parse(localStorage.getItem("userData")),
            data_analytics: {},
            statistiqueProjets: {},
            statistiqueProjetsRisque: [],
            statistiqueProjetsDevis: null,
            statistiqueProspect: null,
            statistiqueEvenementsMsg: [],
            statistiqueEvenementsTache: [],
            statistiqueContratsAssRis: [],
            statistiqueCommissions: [],

            data: {
                congratulations: {
                    name: 'John',
                    saleToday: '48900',
                },
                statisticsItems: [
                    {
                        icon: 'TrendingUpIcon',
                        color: 'light-primary',
                        title: '230k',
                        subtitle: 'Sales',
                        customClass: 'mb-2 mb-xl-0',
                    },
                    {
                        icon: 'UserIcon',
                        color: 'light-info',
                        title: '8.549k',
                        subtitle: 'Customers',
                        customClass: 'mb-2 mb-xl-0',
                    },
                    {
                        icon: 'BoxIcon',
                        color: 'light-danger',
                        title: '1.423k',
                        subtitle: 'Products',
                        customClass: 'mb-2 mb-sm-0',
                    },
                    {
                        icon: 'DollarSignIcon',
                        color: 'light-success',
                        title: '$9745',
                        subtitle: 'Revenue',
                        customClass: '',
                    },
                ],
                statisticsOrder: {
                    series: [
                        {
                            name: '2020',
                            data: [45, 85, 65, 45, 65],
                        },
                    ],
                },
                statisticsProfit: {
                    series: [
                        {
                            data: [0, 20, 5, 30, 15, 45],
                        },
                    ],
                },
                earningsChart: {
                    series: [53, 16, 31],
                },
                revenue: {
                    years: ['2020', '2019', '2018'],
                    price: '25,852',
                    budget: '56,800',
                    revenueReport: {
                        series: [
                            {
                                name: 'Earning',
                                data: [95, 177, 284, 256, 105, 63, 168, 218, 72],
                            },
                            {
                                name: 'Expense',
                                data: [-145, -80, -60, -180, -100, -60, -85, -75, -100],
                            },
                        ],
                    },
                    budgetChart: {
                        series: [
                            {
                                data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
                            },
                            {
                                data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
                            },
                        ],
                    },
                },
                companyTable: [
                    {
                        avatarImg: require('@/assets/images/icons/toolbox.svg'),
                        title: 'Dixons',
                        subtitle: 'meguc@ruj.io',
                        avatarIcon: 'MonitorIcon',
                        avatarColor: 'light-primary',
                        avatarTitle: 'Technology',
                        viewTitle: '23.4k',
                        viewsub: 'in 24 hours',
                        revenue: '891.2',
                        sales: '68',
                        loss: true,
                    },
                    {
                        avatarImg: require('@/assets/images/icons/parachute.svg'),
                        title: 'Motels',
                        subtitle: 'vecav@hodzi.co.uk',
                        avatarIcon: 'CoffeeIcon',
                        avatarColor: 'light-success',
                        avatarTitle: 'Grocery',
                        viewTitle: '78k',
                        viewsub: 'in 2 days',
                        revenue: '668.51',
                        sales: '97',
                        loss: false,
                    },
                    {
                        avatarImg: require('@/assets/images/icons/brush.svg'),
                        title: 'Zipcar',
                        subtitle: 'davcilse@is.gov',
                        avatarIcon: 'WatchIcon',
                        avatarColor: 'light-warning',
                        avatarTitle: 'Fashion',
                        viewTitle: '162',
                        viewsub: 'in 5 days',
                        revenue: '522.29',
                        sales: '62',
                        loss: false,
                    },
                    {
                        avatarImg: require('@/assets/images/icons/star.svg'),
                        title: 'Owning',
                        subtitle: 'us@cuhil.gov',
                        avatarIcon: 'MonitorIcon',
                        avatarColor: 'light-primary',
                        avatarTitle: 'Technology',
                        viewTitle: '214',
                        viewsub: 'in 24 hours',
                        revenue: '291.01',
                        sales: '88',
                        loss: false,
                    },
                    {
                        avatarImg: require('@/assets/images/icons/book.svg'),
                        title: 'Cafés',
                        subtitle: 'pudais@jife.com',
                        avatarIcon: 'CoffeeIcon',
                        avatarColor: 'light-success',
                        avatarTitle: 'Grocery',
                        viewTitle: '208',
                        viewsub: 'in 1 week',
                        revenue: '783.93',
                        sales: '16',
                        loss: true,
                    },
                    {
                        avatarImg: require('@/assets/images/icons/rocket.svg'),
                        title: 'Kmart',
                        subtitle: 'bipri@cawiw.com',
                        avatarIcon: 'WatchIcon',
                        avatarColor: 'light-warning',
                        avatarTitle: 'Fashion',
                        viewTitle: '990',
                        viewsub: 'in 1 month',
                        revenue: '780.05',
                        sales: '78',
                        loss: false,
                    },
                    {
                        avatarImg: require('@/assets/images/icons/speaker.svg'),
                        title: 'Payers',
                        subtitle: 'luk@izug.io',
                        avatarIcon: 'WatchIcon',
                        avatarColor: 'light-warning',
                        avatarTitle: 'Fashion',
                        viewTitle: '12.9k',
                        viewsub: 'in 12 hours',
                        revenue: '531.49',
                        sales: '42',
                        loss: false,
                    },
                ],
                meetup: {
                    mediaData: [
                        { avatar: 'CalendarIcon', title: 'Mardi 21 Juin 2022', subtitle: 'de 9:00 à 18:00' },
                        { avatar: 'MapPinIcon', title: 'Centre des Congrès', subtitle: 'Lyon' },
                    ],
                    avatars: [
                        { avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'), fullName: 'Billy Hopkins' },
                        { avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'), fullName: 'Amy Carson' },
                        { avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'), fullName: 'Brandon Miles' },
                        { avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'), fullName: 'Daisy Weber' },
                        { avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'), fullName: 'Jenny Looper' },
                    ],
                },
                goalOverview: {
                    completed: '786,617',
                    inProgress: '13,561',
                    series: [83],
                },
                transactionData: [
                    {
                        mode: 'Santé TNS',
                        types: 'Starbucks',
                        avatar: 'PocketIcon',
                        avatarVariant: 'light-primary',
                        payment: '28.1',
                        deduction: true,
                    },
                    {
                        mode: 'Auto',
                        types: 'Add Money',
                        avatar: 'CheckIcon',
                        avatarVariant: 'light-success',
                        payment: '19.9',
                        deduction: false,
                    },
                    {
                        mode: 'Prévoyance tns',
                        types: 'Add Money',
                        avatar: 'DollarSignIcon',
                        avatarVariant: 'light-danger',
                        payment: '12.5',
                        deduction: false,
                    },
                    {
                        mode: 'MRH',
                        types: 'Ordered Food',
                        avatar: 'CreditCardIcon',
                        avatarVariant: 'light-warning',
                        payment: '15.5',
                        deduction: true,
                    },
                    {
                        mode: 'Autres',
                        types: 'Refund',
                        avatar: 'TrendingUpIcon',
                        avatarVariant: 'light-info',
                        payment: '24',
                        deduction: false,
                    },
                ],
            },
        }
    },
    created() {
        this.showLoading = true

        const requests = [];
       
            requests.push(this.$http.get('/analytics/data')
                .then(response => { this.data_analytics = response.data }))
            requests.push(this.$http.get('/statistiques/getStatistiqueProjets')
                .then(response => {
                    this.statistiqueProjets = response.data.data
                })
                .catch(err => {
                }))
            requests.push(this.$http.get('/statistiques/getStatistiqueContratByAssRis')
                .then(response => {
                    this.statistiqueContratsAssRis = response.data.data

                })
                .catch(err => {
                }))
            requests.push(this.$http.get('/statistiques/getStatistiqueCommissions')
                .then(response => {
                    this.statistiqueCommissions = response.data.data
                })
                .catch(err => {
                }))
            requests.push(this.$http.get('/statistiques/getStatistiqueProjetsByRisque')
                .then(response => {
                    this.statistiqueProjetsRisque = response.data.data
                })
                .catch(err => {

                }))
            requests.push(this.$http.get('/statistiques/getStatistiqueProspect')
                .then(response => {
                    this.statistiqueProspect = response.data.data
                })
                .catch(err => {
                }))

            requests.push(this.$http.post('/evenements/userMessageData', { evenement_type: 'Message', take: 3 })
                .then(response => {
                    this.statistiqueEvenementsMsg = response.data.events_returned
                })
                .catch(err => {
                }))
            requests.push(this.$http.post('/calendar/taches', { user_id: JSON.parse(localStorage.getItem('userData')).id })
                .then(response => {
                    this.statistiqueEvenementsTache = response.data
                })
                .catch(err => {
                }))

        return Promise.all(requests).then(res => this.showLoading = false)



    },
    methods: {

    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
<style scoped>
.card-body {
    padding: 5rem 1.5rem;
}

.tasks-row .card-body {
    min-height: 200px;
}

.tasks-row .card-header {
    border-radius: 0;
}

.card-header {
    background-color: #4d25bc;
    justify-content: center;
}

.card-header h5 {
    color: #ffffff;
    text-transform: uppercase;
}

.client_stats.card>.card-body {
    padding: 1rem !important;
}
</style>

