<template>
    <section id="dashboard-analytics">

      <div v-if="currentUser.assureur_user && currentUser.assureur_user != null">
        <assureur :assureur_id="currentUser.assureur_user.assureur_id"/>
      </div>

      <div v-else>
        <courtier/>
      </div>
   </section>
</template>

<script>
import { BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BOverlay } from 'bootstrap-vue'
import assureur from './home/Assureur.vue'
import courtier from './home/Courtier.vue'



export default {
  components: {
    BCardHeader,
    BCardTitle,
    BCardText,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardBody,
    assureur,
    courtier
  },
    data() {
    return {
      showLoading: false,
      currentUser: JSON.parse(localStorage.getItem("userData")),

    }
  },

}
</script>


