<template>
  <b-card title="VOTRE AGENDA">
    <template v-if="statistiqueEvenements.length > 0">
      <app-timeline>
        <app-timeline-item v-for="(event,index) in statistiqueEvenements" :key="index"
          :title="event.titre"
          :subtitle="event.description"
          :icon="event.type == 'Tache' ? 'GridIcon' : 'ClockIcon'"
          :time="event.allDay == 0 ? getDateFormated(event.dateDebut) +' au '+ getDateFormated(event.dateFin) : getDateFormatedAllDay(event.dateDebut)"
          :variant="event.type == 'Tache' ? 'primary' : 'warning'"
          :guests="event.calendar_user"
        />
      </app-timeline>
    </template>
    <template v-else>
      <h3 class="text-center mt-3">
        Aucune tâche
      </h3>
    </template>
  </b-card>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  BCard, BBadge, BCardTitle, BCardText, BAvatar, BButton,BCardHeader,BCardBody
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BAvatar,
    BCardTitle,
    BCardText,
    BCardHeader,
    BCardBody,
    AppTimeline,
    AppTimelineItem
  },
  directives: {
    Ripple,
  },
  props: {
    statistiqueEvenements: {
      type: Array,
      default: () => [],
    },
  },
  methods:{
    getDateFormated(date){
      return date ? moment(String(date)).format('DD/MM H:mm') :''
    },
    getDateFormatedAllDay(date){
      return date ? 'Toute la journée du '+ moment(String(date)).format('DD/MM') :''
    }
  }
}
</script>
