<template>
    <b-card no-body class="card-developer-meetup">
        <div class="rounded-top text-center" style="">
            <b-carousel id="carousel-fade" style="height:520px;text-shadow: 0px 0px 1px #000" fade controls :interval="4000"
                class="">

                <a :href="carousel.url" target="_blank" v-for="carousel in carouselsData" :key="carousel.id">
                    <b-carousel-slide v-if="!carousel.base64.isError" :img-src="carousel.base64"></b-carousel-slide>
                </a>
            </b-carousel>
        </div>
    </b-card>
</template>

<script>
import {
    BCard, BImg, BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody, BCarousel, BCarouselSlide
} from 'bootstrap-vue'

export default {
    components: {
        BCard,
        BImg,
        BCardBody,
        BCardText,
        BCardTitle,
        BMedia,
        BMediaAside,
        BAvatar,
        BAvatarGroup,
        BMediaBody,
        BCarousel,
        BCarouselSlide
    },
    directives: {
        'b-tooltip': VBTooltip,
    },
    props: {
        assureur_id: Number,
    },
    data() {
        return {
            carouselsData: []
        }
    },
    created() {
        this.fetchCarousels();
    },
    methods: {
        fetchCarousels() {
            this.carouselsData = [];
            this.$http
                .get(`/carousels/fetchCarroselByAssureur/${this.assureur_id}`)
                .then((res) => {
                    if (res.data.success) {
                        this.carouselsData = res.data.data;
                    } else {
                        this.messageToast(
                            "Nous n'avons pas pu trouver la ressource que vous avez demandée.",
                            "Erreur",
                            "warning"
                        );
                    }
                    console.log(res.data);
                })
                .catch((err) => {
                    this.messageToast(err.response.data.errors[0], "Erreur", "error");
                    console.error(err);
                });
        },

    }
}
</script>

<style>
.card-developer-meetup .carousel-control-prev-icon,
.card-developer-meetup .carousel-control-next-icon {
    width: 30px;
    height: 30px;
    background-color: #767171;
}

.card-developer-meetup .carousel-caption {
    right: 0;
    left: 0;
    padding-top: 0;
    padding-bottom: 10px;
}

#carousel-fade .carousel-control-prev-icon,
#carousel-fade .carousel-control-next-icon {
    display: none;
}

#carousel-fade:hover .carousel-control-prev-icon,
#carousel-fade:hover .carousel-control-next-icon {
    display: block;
    transition: 0.3s;
}
</style>
