<template>
    <b-card no-body class="card-browser-states">
        <b-card-header>
            <div>
                <b-card-title class="text-primary">Statistiques générales
                </b-card-title>
            </div>
        </b-card-header>

        <b-card-body class="p-1">

            <b-row class="ml-1">
                <section style="width:100%;" class="part">
                    <div class="w-100 d-flex justify-content-evenly">
                        <div class="w-100 d-flex align-items-center  justify-content-start" style="font-weight: 900;">Nombre
                            total de contrats</div>
                        <div class="w-50  d-flex align-items-center justify-content-center bd-highlight">{{ statistiqueContrats }}</div>
                    </div>
                </section>

                <section style=" width: 100%; margin-top:10px;"  >
                        <div class="w-100 d-flex justify-content-evenly">
                            <div class="w-100 d-flex align-items-center  justify-content-start" style="font-weight: 900;">Nombre total de devis</div>
                            <div class="w-50  d-flex align-items-center justify-content-center bd-highlight">{{ statistiqueDevis }}</div>
                        </div>

                        <div>
                            <div class="w-100 d-flex justify-content-evenly"   >
                                <div class="w-100 d-flex align-items-center  justify-content-start" style="margin-top:10px; font-weight: 900;">Répartition du nombre de devis par produit</div>
                            </div>

                            <div  style="margin:0px;height:80px;" id="scrollbar2" >
                                <div v-for="(produit) in devisParProduitStatistique" :key="produit.id" class="w-100 d-flex justify-content-evenly"  >
                                    <div class="w-100 d-flex align-items-center  justify-content-start" v-if="produit.total != 0" >- {{ produit.risque.label }}</div>
                                    <div style="margin-bottom:5px" class="w-50  d-flex align-items-center justify-content-center bd-highlight" v-if="produit.total != 0"> {{ produit.total }}</div>
                                </div>
                            
                            
            
                            </div>
                        </div>
                    </section>
                

                 <section style="width:100%; margin-top:10px;">
                        <div class="w-100 d-flex justify-content-evenly">
                            <div class="w-100 d-flex align-items-center  justify-content-start" style="font-weight: 900;">Nombre de tarifications par produit</div>
                            <!-- <div style="margin-bottom:5px" class="w-50  d-flex align-items-center justify-content-center bd-highlight">{{ totalTarification }}</div> -->
                        </div>

                        <!-- <div class="w-100 d-flex justify-content-evenly"  >
                            <div class="w-100 d-flex align-items-center  justify-content-start" style="font-weight: 900;">Réparatition par produit</div>
                        </div> -->
                        <div  style="margin:0px;height:80px;" id="scrollbar2">
                            <div v-for="(produit) in tarificationRapideParRisque" :key="produit.id" class="w-100 d-flex justify-content-evenly"  >
                                <div class="w-100 d-flex align-items-center  justify-content-start">- {{ produit.risques.label }}</div>
                                <div style="margin-bottom:5px" class="w-50  d-flex align-items-center justify-content-center bd-highlight">{{ produit.total }}</div>
                            </div>

                        </div>

                    
                    </section>


                <section style="width:100%; margin-top:10px;">
                    <div class="w-100 d-flex justify-content-evenly">
                        <div class="w-100 d-flex align-items-center  justify-content-start" style="font-weight: 900;">Nombre de connexion à la plateforme (sur le mois)</div>
                        <div style="margin-bottom:5px"
                            class="w-50  d-flex align-items-center justify-content-center bd-highlight">{{ nombreConnexion }}</div>
                    </div>
                </section>

                <section style="width:100%; margin-top:10px;">
                    <div class="w-100 d-flex justify-content-evenly">
                        <div class="w-100 d-flex align-items-center  justify-content-start" style="font-weight: 900;">Nombre
                            d'utilisateur unique de la plateforme (sur le mois )</div>
                        <div style="margin-bottom:5px"
                            class="w-50  d-flex align-items-center justify-content-center bd-highlight">{{ nombreUtilisateur }}</div>
                    </div>
                </section>
            </b-row>

        </b-card-body>
    </b-card>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    VBPopover,
    VBTooltip,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Ripple from "vue-ripple-directive";

/* eslint-disable global-require */
export default {
    components: {
        BRow,
        BCard,
        BCol,
        BCardHeader,
        BCardTitle,
        BCardText,
        BCardBody,
        BMedia,
        BMediaAside,
        BMediaBody,
        BImg,
        BDropdown,
        BDropdownItem,
        VueApexCharts,
    },
    directives: {
        "b-tooltip": VBTooltip,
        "b-popover": VBPopover,
        Ripple,
    },
    data() {
        return {
            // totalTarification:0
            
        }
    },
     props: {
        statistiqueDevis: Number,
        statistiqueContrats: Number,
        nombreUtilisateur: Number,
        nombreConnexion: Number,
        tarificationRapideParRisque: {
            type: Array,
            default: [],

        },
        devisParProduitStatistique: {
            type: Array,
            default:[],
            
        }

    },
    watch: {
        // 'tarificationRapideParRisque': {
        //     deep: true,
        //     immediate: true,
        //     handler(val) {
        //        this.totalTarification = val.reduce((acc, obj) => acc + obj.total, 0);

        //     }
        // }
    }


}

</script>
<style>

    #scrollbar2{
        height:75px;
        overflow-y:auto;
    }


   #scrollbar2::-webkit-scrollbar {
        width: 4px;
    }
        
    #scrollbar2::-webkit-scrollbar-track {
        border-radius: 8px;
        background-color: #e7e7e7;
        border: 1px solid #cacaca;
        box-shadow: inset 0 0 6px rgb(174, 145, 252);
    }
    
    #scrollbar2::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: rgb(77, 37, 188);
    }

</style>