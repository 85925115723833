<template>
  <b-card no-body class="card-browser-states">
    <b-card-header>
      <div>
        <b-card-title
          >Commissions / Chiffre d'affaire
          <span
            v-b-popover.hover.right.html="'Tous les contrats externes dont les champs commissions ont bien été renseignés (hors concurrence) (hors résiliés, sans effet, temporaires si la date de fin est inférieur à la date du jour)'"
            title="Information"
            class=""
            variant="outline-primary"
            ><feather-icon
              icon="HelpCircleIcon"
              class="text-muted cursor-pointer"
              size="21" /></span
        ></b-card-title>
      </div>
    </b-card-header>

    <!-- body -->
    <b-card-body v-if="renderComponent" class="p-0">
      <b-row class="match-height px-2" >
        <b-col lg="4" md="4">
          <div
            class="box"
            :class="is_assureur ? 'active' : ''"
            ref="box_ass"
            @click="showAssureurs"
          >
            <feather-icon icon="LayersIcon" size="24" />

            <p>Assureurs</p>
          </div>
        </b-col>
        <b-col lg="4" md="4">
          <div
            class="box"
            :class="is_risque ? 'active' : ''"
            ref="box_ris"
            @click="showRisques"
          >
            <feather-icon icon="UmbrellaIcon" size="24" />

            <p>Risques</p>
          </div>
        </b-col>
        <b-col lg="4" md="4">
          <div class="box-prime">
            <p class="prime_label text-center">Total des frais de courtage</p>
            <p class="prime_value text-center">
              <b>{{ formatPrime(fraisCourtage) }}€</b>
            </p>
          </div>
        </b-col>
      </b-row>
      <div class="browser-states px-2" v-for="(sd, index) in statsData" :key="index">
        <div
          class="w-100 d-flex justify-content-evenly"
          v-if="sd.commission_sum > 0"
        >
          <div class="w-100 d-flex align-items-center justify-content-start">
            <b-media style="margin-top: 9px" no-body>
              <b-media-body>
                <h6 class="transaction-title text-uppercase">
                  {{ is_assureur ? sd.nom_com_assureur : sd.type }}
                </h6>
              </b-media-body>
            </b-media>
          </div>
          <div
            class="w-50 d-flex align-items-center justify-content-center bd-highligh"
          >
            <span class="font-weight-bold text-body-heading mr-1">
              {{ (sd.commission_sum && sd.commission_sum>0 ? formatPrime(sd.commission_sum) : formatPrime(0)) + '€' }} ({{
                getPercent(
                  sd.commission_sum,
                  is_assureur ? "assureur" : "risque"
                )
              }}
              %)
            </span>
            <vue-apex-charts
              type="radialBar"
              height="30"
              width="30"
              :options="
                is_assureur
                  ? chartDataAss[index].options
                  : chartDataRis[index].options
              "
              :series="[
                getPercent(
                  sd.commission_sum,
                  is_assureur ? 'assureur' : 'risque'
                ),
              ]"
            />
          </div>
        </div>
      </div>
      <b-row class="text-center mx-0 mt-2 match-height w-full">
      <b-col
        cols="6"
        class="border-top border-right  d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          Total commissions
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ (totalCommission && totalCommission>0 ? formatPrime(totalCommission) : formatPrime(0)) + '€' }}
        </h3>
      </b-col>
 
      <b-col
        cols="6"
        class="border-top d-flex align-items-between flex-column py-1  "
      >
        <b-card-text class="text-muted mb-0">
          Prévisionnel
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ (totalCommissionPrev && totalCommissionPrev>0 ? formatPrime(totalCommissionPrev) : formatPrime(0)) + '€' }}
        </h3>
      </b-col>
    </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  VBPopover,
  VBTooltip,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import Ripple from "vue-ripple-directive";

/* eslint-disable global-require */
const $trackBgColor = "#e9ecef";
export default {
  props: ["statistiqueCommissions"],
  watch: {
    statistiqueCommissions: function (old, nld) {
      this.filterStateData(old);
      this.fillChartData(old);
      this.fraisCourtage = old.fraisCourtage
      this.totalCommission = old.totalCommission[0]
      this.totalCommissionPrev = old.totalCommissionPrev[0];
    },
  },
  components: {
    BRow,
    BCard,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    VueApexCharts,
  },
  data() {
    return {
      is_assureur: true,
      is_risque: false,
      usedIndexes: [],
      chartDataAss: [],
      chartDataRis: [],
      fraisCourtage:0,
      totalCommission:0,
      totalCommissionPrev:0,
      chartColor: [
        $themeColors.primary,
        $themeColors.warning,
        $themeColors.secondary,
        $themeColors.info,
        $themeColors.danger,
      ],

      chart: {
        series: [90],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: "22%",
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: "always",
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: "round",
          },
        },
      },
      renderComponent: true,
      statsData: [],
      totalStateDataAss: 0,
      totalStateDataRis: 0,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover,
    Ripple,
  }, 
   methods: {
    showAssureurs() {
      this.statsData = this.statistiqueCommissions.insuranceCommission.slice(
        0,
        5
      );
      this.is_assureur = true;
      this.is_risque = false;
    },
    showRisques() {
      this.statsData = this.statistiqueCommissions.risqueCommission;
      this.is_assureur = false;
      this.is_risque = true;
    },
    filterStateData(data) {
      this.statsData = this.statistiqueCommissions.insuranceCommission.slice(
        0,
        5
      );
      data.insuranceCommission.map((dt) => {
        this.totalStateDataAss += dt.commission_sum;
      });
      data.risqueCommission.map((dt) => {
        this.totalStateDataRis += dt.commission_sum;
      });
      this.renderComponent = true;
    },
    getPercent(value, type) {
      return type == "assureur"
        ? ((value / this.totalStateDataAss) * 100).toFixed(1)
        : ((value / this.totalStateDataRis) * 100).toFixed(1);
    },
    fillChartData(data) {
      this.chartDataRis = [];
      this.chartDataAss = [];
      for (let i = 0; i < data.insuranceCommission.length; i += 1) {
        const chartClone = JSON.parse(JSON.stringify(this.chart));
        chartClone.options.colors[0] = this.chartColor[this.getRandomIndex()];
        this.chartDataAss.push(chartClone);
      }
      for (let i = 0; i < data.risqueCommission.length; i += 1) {
        const chartClone = JSON.parse(JSON.stringify(this.chart));
        chartClone.options.colors[0] = this.chartColor[this.getRandomIndex()];
        this.chartDataRis.push(chartClone);
      }
    },
    getRandomIndex() {
      // Check if all indexes have been used
      if (this.usedIndexes.length === this.chartColor.length) {
        // If all indexes have been used, reset the usedIndexes array
        this.usedIndexes = [];
      }

      // Generate a random index until an unused index is found
      let index;
      do {
        index = Math.floor(Math.random() * this.chartColor.length);
      } while (this.usedIndexes.includes(index));

      // Add the index to the usedIndexes array and return it
      this.usedIndexes.push(index);

      return index;
    },
    formatPrime(price) {
      const priceString = price.toFixed(2);
      const decimalIndex = priceString.indexOf(".");
      const integerPart = priceString.slice(0, decimalIndex);
      const decimalPart = priceString.slice(decimalIndex + 1);
      const integerWithSeparator = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        " "
      );
      const formattedPrice = integerWithSeparator + "," + decimalPart;
      return formattedPrice;
    },
  },
};
</script>
<style>
.box {
  width: 100% !important;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 6px;
  cursor: pointer;
}
.box p {
  margin-bottom: 0 !important;
  font-size: 1rem;
}
.box-prime p {
  margin-bottom: 0 !important;
}
.box.active {
  border-color: #4d25bc;
}
.prime_label {
  font-size: 0.95rem;
}
.prime_value {
  font-size: 1.1rem;
}
.box-prime {
  height: 90px;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>