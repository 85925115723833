<template>
    <div class=" d-flex  align-items-center">

        <div>

            <b-avatar class="" variant="light-success" size="45">
                <feather-icon size="21" icon="UsersIcon" />
            </b-avatar>
        </div>
        <div class="truncate ml-2">
            <h2 class="mb-25 font-weight-bolder">
                {{ nombreCodeParAssureurMois }}
            </h2>
            <span>Cabinets codés</span>
        </div>
    </div>
</template>
  
<script>
import { BCard, BAvatar } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'


export default {
    components: {
        BCard,
        BAvatar,
        VueApexCharts,
    },
    props: {

        nombreCodeParAssureurMois:Number
    },
    data() {
        return {

        }
    },
}
</script>
  